body {
  background-color: #eeeeee;
}

@media (max-width: 991.98px) {
  .show {
    height: calc(100vh - 56px);
  }

  .navbar {
    align-items: start;
  }

  div.navbar-nav {
    width: 50vw;
    margin-top: 20vh;
  }


  div.navbar-nav>.nav-link {
    font-size: 2.5rem;
  }
}

.main {
  margin-top: 62px;
  min-height: calc(100vh - 62px);
}

@media (max-width: 991.98px) {
  .main {
    margin-top: 56px;
  }
}

.home-default {
  position: absolute;
  z-index: 1000;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

}



.p-search.unsearched {
  width: 80vw;
  animation: transitionIn 2s;
}

@media (min-width : 576px) {
  .p-search.unsearched {
    width: 55vw;
  }
}

@keyframes transitionIn {
  from {
    opacity: 0;
    /* top: 36%;
    transform: translate(-50%, -60%); */
  }

  to {
    opacity: 1;
    /* top: 50%;
    transform: translate(-50%, -50%); */
  }
}

.searched {
  position: fixed;
  z-index: 1000;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
}

.searched {
  width: 90vw;
}

.searched .btn-secondary {
  height: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
}

@media (min-width : 576px) {
  .searched {
    width: 50vw;
  }

  .searched form {
    width: 50vw;
  }
}

.patients {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin-top: 6.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

@media (max-width: 991.98px) {
  .patients {
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 1rem; */
  }
}

@media (max-width: 767.98px) {
  .patients {
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 1rem; */
  }
}
@media (max-width: 575.98px) {
  .patients {
    grid-template-columns: repeat(1, 1fr);
    /* grid-gap: 1rem; */
  }
}

.patients img {
  align-self: center;
}

/* // Small devices (landscape phones, less than 768px) */
/* @media (max-width: 767.98px) { ... } */

/* // Medium devices (tablets, less than 992px) */
/* @media (max-width: 991.98px) { ... } */

/* // Large devices (desktops, less than 1200px) */
/* @media (max-width: 1199.98px) { ... } */

/* .card.p-details {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 50%  50%;
  grid-template-areas: "details-one details-two";
}

  .details-1 {
    grid-area: details-one;
  }
  .details-2 {
    grid-area: details-two;
  } */

.details-1 {
  display: grid;
}

.details-1 img {
  align-self: center;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.item-head {
  font-weight: 600;
  background-color: #F2F2F2;
}

.b {
  font-weight: 600;
}

/*   
  .details-2 tr td:nth-child(2) {
    padding-left: 3rem;
  } */

.p-details .col {
  margin: 0.5rem;
  padding: 0.5rem;
}

.rpt {
  overflow-x: auto;
}

.rpt a {
  display: block;
  width: 100%;
  height: 100%;
}